import React, { useCallback } from 'react';

import { useWallet } from '@solana/wallet-adapter-react';
import {
  PublicKey,
  VersionedTransaction,
} from '@solana/web3.js';

import { PhantomProvider } from './types';

interface SwapComponentProps {
    tokenBalance: number;
    tokenMintAddress: string;
    destinationTokenMintAddress: string;
    amount: string;
    slippageBps: number;  // Add this line
    onSuccessfulSwap: () => void; // Add this line
}

const SwapComponent: React.FC<SwapComponentProps> = ({ tokenBalance, tokenMintAddress, destinationTokenMintAddress, amount, slippageBps, onSuccessfulSwap }) => {
    console.log('tokenBalance', tokenBalance, 'tokenMintAddress', tokenMintAddress, 'destinationTokenMintAddress', destinationTokenMintAddress, 'amount', amount, 'slippageBps', slippageBps);
    const wallet = useWallet();

    const performSwap = useCallback(async () => {
        if (!wallet.publicKey || !wallet.signTransaction) {
            console.log('Wallet not connected');
            return;
        }
        const referralAccountPubkey = "8H46fZEdahGVqfSBvS2BZJZuLHwcL6JJueiVD8YrXjzW"
        let base58publicKey = new PublicKey(referralAccountPubkey);
        const mint = new PublicKey(destinationTokenMintAddress)
        const [feeAccount] = await PublicKey.findProgramAddressSync(
            [
              Buffer.from("referral_ata"),
              base58publicKey.toBuffer(), // your referral account public key
              mint.toBuffer(), // the token mint, output mint for ExactIn, input mint for ExactOut.
            ],
            new PublicKey("REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3") // the Referral Program
          );
        
        try {
            const amountAsString = tokenBalance.toString();
            const quoteResponse = await getSwapQuote(tokenMintAddress, destinationTokenMintAddress, amountAsString, slippageBps);

            const swapTransactionResponse = await fetch('https://quote-api.jup.ag/v6/swap', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    quoteResponse,
                    feeAccount: feeAccount,
                    userPublicKey: wallet.publicKey.toString(),
                    wrapAndUnwrapSol: true
                })
            });
            // const swapTransactionBuf = Buffer.from(swapResponse.swapTransaction, 'base64');
            // const transaction = VersionedTransaction.deserialize(swapTransactionBuf);
            // transaction.sign([wallet]);
            const { swapTransaction } = await swapTransactionResponse.json();
            console.dir(swapTransaction, {depth: null})
            const swapTransactionBuf = Buffer.from(swapTransaction, 'base64');
            console.dir(swapTransactionBuf, {depth: null})
            const versionedTransaction = VersionedTransaction.deserialize(swapTransactionBuf);
            
            // Ensure you have the Phantom provider
            const provider = getProvider(); // Implement this function to get the Phantom provider
    
            if (!provider) {
                console.error("Phantom provider is not available");
                return;
            }
            console.dir(versionedTransaction, {depth: null})
            // Use Phantom's signAndSendTransaction method
            const { signature } = await provider.signAndSendTransaction(versionedTransaction);
            console.log(`Swap successful, transaction ID: https://solscan.io/tx/${signature}`);
            onSuccessfulSwap();

        } catch (error) {
          console.error('Swap failed:', error);
        }
      }, [wallet, tokenBalance, tokenMintAddress, destinationTokenMintAddress, amount, slippageBps, onSuccessfulSwap]);
    
    return (
        <button className="swap-button" onClick={performSwap} disabled={!wallet.connected}>
            Dump This Garbage
        </button>
    );
};
const getProvider = (): PhantomProvider | undefined => {
    if ('phantom' in window) {
      const anyWindow: any = window;
      const provider = anyWindow.phantom?.solana;
  
      if (provider?.isPhantom) {
        return provider;
      }
    }
  
    window.open('https://phantom.app/', '_blank');
  };
async function getSwapQuote(tokenMintAddress: string, destinationTokenMintAddress: string, amount: string, slippageBps: number) {
    const url = `https://quote-api.jup.ag/v6/quote?inputMint=${tokenMintAddress}&outputMint=${destinationTokenMintAddress}&amount=${amount}&slippageBps=${slippageBps}&platformFeeBps=50`;

    try {
        const response = await fetch(url);
        console.dir(response, {depth: null})
        const quoteResponse = await response.json();
        console.dir(quoteResponse, {depth: null})
        if (!response.ok) {
            throw new Error(`Error getting quote: ${response.status} ${quoteResponse.error}`);
        }

        return quoteResponse;
    } catch (error) {
        console.error('Error fetching swap quote:', error);
        throw new Error('Failed to get swap quote');
    }
}

export default SwapComponent;
