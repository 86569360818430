import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useWallet } from '@solana/wallet-adapter-react';
import {
  PublicKey,
  VersionedTransaction,
} from '@solana/web3.js';

import SwapComponent from './SwapComponent'; // Import your SwapComponent
import { PhantomProvider } from './types';

export const performSwap = async (
  wallet,
  tokenMintAddress,
  destinationTokenMintAddress,
  amount,
  slippageBps,
  onSuccessfulSwap
) => {
  if (!wallet.publicKey || !wallet.signTransaction) {
    throw new Error("Wallet not connected");
  }

  try {
    const referralAccountPubkey =
      "8H46fZEdahGVqfSBvS2BZJZuLHwcL6JJueiVD8YrXjzW";
    let base58publicKey = new PublicKey(referralAccountPubkey);
    const mint = new PublicKey(destinationTokenMintAddress);

    const [feeAccount] = await PublicKey.findProgramAddressSync(
      [
        Buffer.from("referral_ata"),
        base58publicKey.toBuffer(),
        mint.toBuffer(),
      ],
      new PublicKey("REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3")
    );

    const quoteResponse = await getSwapQuote(
      tokenMintAddress,
      destinationTokenMintAddress,
      amount,
      slippageBps
    );

    const swapTransactionResponse = await fetch(
      "https://quote-api.jup.ag/v6/swap",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          quoteResponse,
          feeAccount: feeAccount,
          userPublicKey: wallet.publicKey.toString(),
          wrapAndUnwrapSol: true,
        }),
      }
    );

    const { swapTransaction } = await swapTransactionResponse.json();
    const swapTransactionBuf = Buffer.from(swapTransaction, "base64");
    const versionedTransaction =
      VersionedTransaction.deserialize(swapTransactionBuf);

    // Ensure you have the Phantom provider
    const provider = getProvider(); // Implement or import this function

    if (!provider) {
      console.error("Phantom provider is not available");
      return;
    }

    const { signature } = await provider.signAndSendTransaction(
      versionedTransaction
    );
    console.log(
      `Swap successful, transaction ID: https://solscan.io/tx/${signature}`
    );
    onSuccessfulSwap();
  } catch (error) {
    console.error("Swap failed:", error);
    throw error;
  }
};
function TokenGrid({ initialTokenData }) {
  const [tokenData, setTokenData] = useState(initialTokenData || []);
  const [excludedTokens, setExcludedTokens] = useState([]);
  const wallet = useWallet();
  const [cashOutToken, setCashOutToken] = useState(
    "So11111111111111111111111111111111111111112"
  );
  const tokenOptions = {
    SOL: "So11111111111111111111111111111111111111112",
    USDC: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    Bonk: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
    DONK: "xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp",
    WIF: "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm",
    DONK: "xABfKiG2KCHi6keTeLycW1iK7B52wJmchSWXu3YrsDp",
    BCOQ: "coqRkaaKeUygDPhuS3mrmrj6DiHjeQJc2rFbT2YfxWn",
    POPCAT: "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
  };

  useEffect(() => {
    if (initialTokenData) {
      console.dir(initialTokenData, { depth: null });
      setTokenData(initialTokenData);
    }
  }, [initialTokenData]);

  useEffect(() => {
    console.log("wallet connected", wallet.connected);
    // Listen for changes in wallet connection status
    if (!wallet.connected) {
      console.log("!wallet connected", wallet.connected);
      console.dir(tokenData, { dir: null });
      console.dir(excludedTokens, { dir: null });
      removeAllTokens();

      console.log("!wallet connected!", wallet.connected);
      // Clear the token data when the wallet disconnects
      setTokenData([]);
      setExcludedTokens([]);
      console.log("wallet connected!", wallet.connected);
    }
  }, [wallet.connected]);

  // const [tokenData, setTokenData] = useState(initialTokenData || []);
  const [validPrices, setValidPrices] = useState({});
  const [totalUSDCValue, setTotalUSDCValue] = useState(0);
  const [slippageBps, setSlippageBps] = useState(1000); // Default to 1%

  useEffect(() => {
    let newTotal = 0;
    tokenData.forEach((token) => {
      const worth = calculateWorthInUSDC(token);
      if (!isNaN(worth)) {
        newTotal += parseFloat(worth);
      }
    });

    excludedTokens.forEach((token) => {
      const fetchedPrice = validPrices[token.id];
      if (fetchedPrice) {
        const worth = calculateWorthInUSDC(token, fetchedPrice);
        if (!isNaN(worth)) {
          newTotal += parseFloat(worth);
        }
      }
    });

    setTotalUSDCValue(newTotal);
  }, [tokenData, excludedTokens, validPrices]);

  useEffect(() => {
    const fetchPricesForExcludedTokens = async () => {
      for (const token of excludedTokens) {
        try {
          const price = await fetchPriceFromAPI(token.id);
          if (price) {
            setValidPrices((prevPrices) => ({
              ...prevPrices,
              [token.id]: price,
            }));
          }
        } catch (error) {
          //   console.error(`Error fetching price for token ${token.id}:`, error);
        }
      }
    };

    if (excludedTokens.length > 0) {
      fetchPricesForExcludedTokens();
    }
  }, [excludedTokens]);

  const fetchPriceFromAPI = async (mintAddress) => {
    try {
      const primaryUrl = `https://price.jup.ag/v4/price?ids=${mintAddress}&vsToken=USDC`;
      const response = await fetch(primaryUrl);
      const data = await response.json();

      if (
        data &&
        data.data &&
        data.data[mintAddress] &&
        data.data[mintAddress].price
      ) {
        return data.data[mintAddress].price;
      } else {
        return fetchPriceFromFallbackAPI(mintAddress);
      }
    } catch (error) {
      console.error("Error fetching price:", error);
      throw new Error("Failed to fetch price for " + mintAddress);
    }
  };

  const fetchPriceFromFallbackAPI = async (mintAddress) => {
    try {
      const fallbackUrl = `https://public-api.birdeye.so/defi/price?address=${mintAddress}`;
      const response = await fetch(fallbackUrl, {
        method: "GET",
        headers: {
          "X-API-KEY": "b451adba52344843a0791e808aca0be6",
          "x-chain": "solana",
        },
      });
      const data = await response.json();

      if (data && data.success && data.data && data.data.value) {
        return data.data.value;
      } else {
        throw new Error("Fallback price data not found for " + mintAddress);
      }
    } catch (error) {
      console.error("Error fetching fallback price:", error);
      throw new Error("Failed to fetch fallback price for " + mintAddress);
    }
  };

  function calculateWorthInUSDC(token, fetchedPrice = null) {
    const decimals = token.token_info.decimals || 0;
    const balance = token.token_info.balance || 0;
    const adjustedBalance = balance / Math.pow(10, decimals);

    let worthInUSDC;
    if (token.token_info.price_info) {
      worthInUSDC = token.token_info.price_info.total_price.toFixed(2);
    } else if (fetchedPrice) {
      const totalWorth = adjustedBalance * fetchedPrice;
      worthInUSDC = totalWorth.toFixed(2);
    } else {
      return "Unavailable";
    }

    return worthInUSDC;
  }
  const removeTokensFromGrid = (tokenIds) => {
    setTokenData((prevTokenData) =>
      prevTokenData.filter((token) => !tokenIds.includes(token.id))
    );
  };

  // Function to remove all tokens
  const removeAllTokens = () => {
    const allTokenIds = tokenData.map((token) => token.id);
    removeTokensFromGrid(allTokenIds);
    const otherAllTokes = tokenData.map((token) => token.id);
    removeTokensFromGrid(otherAllTokes);
  };

  const removeTokenFromGrid = (tokenId) => {
    console.log("removeTokenFromGrid", tokenId);
    setTokenData((prevTokenData) => {
      const filteredTokens = prevTokenData.filter(
        (token) => token.id !== tokenId
      );
      console.log(filteredTokens); // Log for testing
      return filteredTokens;
      renderTokenItem();
    });
  };

  const renderTokenItem = (token, index) => {
    console.log("renderTokenItem", token.id);
    console.dir(token, { depth: null });
    if (!wallet.connected) {
      setTokenData([]);

      return null; // Exit the function if the wallet is disconnected
    }

    const shouldRenderToken =
      token.content &&
      token.token_info &&
      token.content.metadata &&
      token.content.files &&
      token.token_info.price_info;
    if (!shouldRenderToken) {
      if (!excludedTokens.includes(token)) {
        setExcludedTokens((prev) => [...prev, token]);
      }
      return null; // Don't render this token
    }

    const { metadata, files } = token.content;
    if (files.length === 0) {
      return null;
    }
    const { balance, decimals } = token.token_info;
    const worthInUSDC = calculateWorthInUSDC(token);
    const adjustedBalance = balance / Math.pow(10, decimals);
    if (adjustedBalance === 0) {
      return null; // Do not render tokens with zero balance
    }
    return (
      <div key={token.id} className="token-item">
        <img src={files[0].uri} alt={metadata.name} />
        <div className="token-info">
          <h3>{metadata.name}</h3>
          <p>Symbol: {metadata.symbol}</p>
          <p>Balance: {adjustedBalance.toLocaleString()}</p>
          <p>USDC Worth: ${worthInUSDC.toLocaleString()}</p>
        </div>
        <div className="swap-button-container">
          <SwapComponent
            tokenBalance={balance}
            tokenMintAddress={token.id}
            destinationTokenMintAddress={cashOutToken}
            slippageBps={slippageBps}
            onSuccessfulSwap={() => removeTokenFromGrid(token.id)}
          />
        </div>
      </div>
    );
  };

  const renderExcludedTokenItem = (token, index) => {
    console.log("renderExcludedTokenItem", token.id);
    console.dir(token, { depth: null });
    const fetchedPrice = validPrices[token.id];
    if (!fetchedPrice) {
      return null;
    }
    const { metadata, files } = token.content;

    if (files.length === 0) {
      return null;
    }
    const { balance, decimals } = token.token_info;
    const worthInUSDC = calculateWorthInUSDC(token, fetchedPrice);
    // const adjustedBalance = balance / Math.pow(10, decimals);
    if (balance === 0) {
      return null; // Do not render tokens with zero balance
    }
    return (
      <div key={`excluded-${token.id}`} className="token-item">
        <img src={files[0].uri} alt={metadata.name} />
        <div className="token-info">
          <h3>{metadata.name}</h3>
          <p>Symbol: {metadata.symbol}</p>
          <p>Balance: {(balance / Math.pow(10, decimals)).toLocaleString()}</p>
          <p>USDC Worth: ${worthInUSDC}</p>
        </div>
        <div className="swap-button-container">
          <SwapComponent
            tokenBalance={balance}
            tokenMintAddress={token.id}
            destinationTokenMintAddress={cashOutToken} // Replace with actual USDC mint address
            slippageBps={slippageBps} // Pass slippageBps here
            onSuccessfulSwap={() => removeTokenFromGrid(token.id)}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!wallet.connected) {
      // When wallet disconnects, clear the token data
      setTokenData([]);
    }
  }, [wallet.connected]);
  // const wallet = useWallet(); // Access the wallet context

  const dumpAll = useCallback(async () => {
    // Combine all tokens into a single list to run through performSwap
    const allTokens = [
      ...tokenData,
      ...excludedTokens.map((token) => ({
        ...token,
        tokenBalance:
          token.token_info.balance * Math.pow(10, token.token_info.decimals),
      })),
    ];

    for (const token of tokenData) {
      try {
        await performSwap(
          wallet,
          token.id,
          cashOutToken, // Replace with actual destinationTokenMintAddress if dynamic
          token.token_info.balance.toString(), // Convert balance to string
          slippageBps,
          () => removeTokenFromGrid(token.id)
        );
      } catch (error) {
        console.error(`Failed to swap token ${token.id}:`, error);
      }
    }
    for (const token of excludedTokens) {
      try {
        await performSwap(
          wallet,
          token.id,
          cashOutToken, // Replace with actual destinationTokenMintAddress if dynamic
          (
            token.token_info.balance * Math.pow(10, token.token_info.decimals)
          ).toString(), // Convert balance to string
          slippageBps,
          () => removeTokenFromGrid(token.id)
        );
      } catch (error) {
        console.error(`Failed to swap token ${token.id}:`, error);
      }
    }
  }, [wallet, tokenData, excludedTokens, slippageBps]);

  const asciiArt = `
DUMP YOUR MEMECOINS, EMBRACE SMART INVESTING.

SAY GOODBYE TO UNDERPERFORMING ASSETS.

MAKE WAY FOR SOLID CHOICES: SOL, USDC, AND MORE.

JOIN A COMMUNITY OF SAVVY INVESTORS – STAY AHEAD.

FOCUS ON QUALITY, DITCH THE HYPE.

SHIFT FROM FLIGHTY TRENDS TO STABLE GAINS.

EMPOWER YOUR PORTFOLIO, MAKE EVERY TOKEN COUNT. 🎉🔥🎇
`;
  return (
    <div className="token-grid">
      <div className="total-usdc-value">
        <h2>Total USDC Value:</h2>
        <p>${totalUSDCValue.toLocaleString()}</p>
        <div className="select-slippage">
          <label htmlFor="slippage-select">Select Slippage BPS:</label>
          <select
            id="slippage-select"
            value={slippageBps}
            onChange={(e) => setSlippageBps(parseInt(e.target.value, 10))}
          >
            {[...Array(10).keys()].reverse().map((i) => (
              <option key={i} value={(10 - i) * 100}>
                {10 - i}%
              </option>
            ))}
          </select>
        </div>
        <div className="select-slippage">
          <label htmlFor="slippage-select">Cash Out Token:</label>
          <select
            id="cashout-token-select"
            value={cashOutToken}
            onChange={(e) => setCashOutToken(e.target.value)}
          >
            {Object.entries(tokenOptions).map(([name, address]) => (
              <option key={address} value={address}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <button className="dump-all-button" onClick={dumpAll}>
          DUMP ALL
        </button>
      </div>
      {tokenData.length === 0 && <pre className="ascii-art">{asciiArt}</pre>}
      {tokenData.map(renderTokenItem)}
      {excludedTokens.map(renderExcludedTokenItem)}
    </div>
  );
}
async function getSwapQuote(
  tokenMintAddress,
  destinationTokenMintAddress,
  amount,
  slippageBps
) {
  const url = `https://quote-api.jup.ag/v6/quote?inputMint=${tokenMintAddress}&outputMint=${destinationTokenMintAddress}&amount=${amount}&slippageBps=${slippageBps}&platformFeeBps=50`;

  try {
    const response = await fetch(url);
    console.dir(response, { depth: null });
    const quoteResponse = await response.json();
    console.dir(quoteResponse, { depth: null });
    if (!response.ok) {
      throw new Error(
        `Error getting quote: ${response.status} ${quoteResponse.error}`
      );
    }

    return quoteResponse;
  } catch (error) {
    console.error("Error fetching swap quote:", error);
    throw new Error("Failed to get swap quote");
  }
}
const getProvider = (): PhantomProvider | undefined => {
  if ("phantom" in window) {
    const anyWindow: any = window;
    const provider = anyWindow.phantom?.solana;

    if (provider?.isPhantom) {
      return provider;
    }
  }

  window.open("https://phantom.app/", "_blank");
};

function useWalletConnectionListener(wallet, onDisconnect) {
  useEffect(() => {
    if (!wallet.connected) {
      onDisconnect();
    }
  }, [wallet.connected, onDisconnect]);
}

export default TokenGrid;
